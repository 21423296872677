.separator {
    border-left: 2px solid #525252;
    align-self: stretch;
    width: 2px;
    margin: 20px;

    display: none;
}

@media (min-width: 992px) {
    .separator {
        display: block;
    }
}

code {
    word-wrap: break-word;
}

table {
    border-collapse: collapse;
    margin: 2rem auto;
}

td, th {
    border: 2px solid #ccc;
    padding: 0.5rem 1rem;
    color: black;
}

iframe, img {
    display: block;
    margin: 2rem auto;
}